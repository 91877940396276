import moment from 'moment'
import _ from 'lodash'

class ReviewsModel {
    constructor ($http, API_URL, APP_URL, CGU_URL, CONTACT_MAIL, $stateParams, $translate) {
        this.$http = $http
        this.API_URL = API_URL
        this.APP_URL = APP_URL
        this.CGU_URL = CGU_URL
        this.CONTACT_MAIL = CONTACT_MAIL
        this.fields = ['ambiance', 'service', 'menu', 'value_for_money']
        this.review = {
            global: 0,
            ambiance: 0,
            service: 0,
            menu: 0,
            value_for_money: 0,
            body: '',
            ambiance_body: '',
            service_body: '',
            menu_body: '',
            value_for_money_body: '',
            tags: []
        }
        this.guests = [{}]
        this.translate = $translate
        this.hover = {}
        this.booking = {}
        this.forbidden = false
        this.step = 1
    }

    init (restaurantId, bookingId, guestId) {
        this.RESTAURANT_ID = restaurantId
        this.BOOKING_ID = bookingId
        this.GUEST_ID = guestId
        this.review.booking_id = this.BOOKING_ID
        this.review.guest_id = this.GUEST_ID
        return this.loadBookingInfo()
    }

    currentFirstName () {
        return this.guest !== undefined
            ? this.guest.firstname
            : this.booking.firstname
    }

    loadBookingInfo () {
        const url = `${this.API_URL}/restaurants/${this.RESTAURANT_ID}/bookings/${this.BOOKING_ID}?with-h=true`
        return this.$http.get(url).then(response => {
            if (response.data === undefined) {
                console.error('An error occured trying to load booking info')
                this.forbidden = true
                return
            }
            this.booking = response.data

            const date = moment(this.booking.day + 'T' + this.booking.time)
            this.booking.formatted_day = date
                .format('LLLL')
                .replace(date.format('LT'), '')
            this.booking.formatted_time = date.format('LT')

            this.review.email = response.data.email

            this.fillGuestInfo()

            this.fillReviewInfo()
        })
    }

    fillGuestInfo () {
        if (this.GUEST_ID !== undefined) {
            this.guest = this.booking.guests.find(
                guest => guest.id === this.GUEST_ID
            )
            if (this.guest === undefined) {
                this.forbidden = true
            }
        }
    }

    fillReviewInfo () {
        let guestId = this.GUEST_ID || null
        let previousReview = _(this.booking.reviews).find(
            r => r.guest_id === guestId
        )
        if (previousReview !== undefined) {
            let {
                global,
                menu,
                ambiance,
                service,
                value_for_money
            } = previousReview
            this.review.global = global
            this.review.menu = menu
            this.review.ambiance = ambiance
            this.review.service = service
            // eslint-disable-next-line
            this.review.value_for_money = value_for_money

            this.step = 4
            this.trackAlreadyReviewed()
        } else {
            this.trackStep(1)
        }

        this.translate.onReady(() => {
            window.document.title = this.translate.instant(`step_${this.step}_title`)
        })
    }

    loadTags () {
        const url = `${this.API_URL}/polarity-tags?limit=200`
        this.$http.get(url).then(response => {
            this.polarityTags = response.data.data
        })
    }

    nextStep () {
        this.step++
        this.trackStep(this.step)
        this.translate.onReady(() => {
            switch (this.step) {
            case 2:
                return (window.document.title = this.translate.instant(
                        'step_2_title'
                    ))
            case 3:
                return (window.document.title = this.translate.instant(
                        'step_3_title'
                    ))

            case 4:
                return (window.document.title = this.translate.instant(
                        'step_4_title'
                    ))
            }
        })
    }

    previousStep () {
        this.step--
        this.trackStep(this.step)
    }

    addGuest () {
        this.guests.push({})
    }

    trackStep (step) {
        try {
            window.ga('send', 'event', 'Steps', 'goto', `Step ${step}`)
        } catch (e) {
            console.error(e)
        }
    }

    trackAlreadyReviewed () {
        try {
            window.ga('send', 'event', 'Skip', 'goto', 'last')
        } catch (e) {
            console.error(e)
        }
    }
}

ReviewsModel.$inject = [
    '$http',
    'API_URL',
    'APP_URL',
    'CGU_URL',
    'CONTACT_MAIL',
    '$stateParams',
    '$translate'
]

export default ReviewsModel
