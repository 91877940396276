import angular from 'angular'
import _ from 'lodash'

export default class StepThreeController {
    constructor ($scope, reviewsModel) {
        this.model = reviewsModel
        this.tagsRatios = [0, 0, 0.2, 0.6, 0.9, 1.0]
        $scope.$watch(angular.bind(this, this.getStep), angular.bind(this, this.init))
    }

    init () {
        if (this.model.step === 3) {
            this.setFields()
            this.setTags()
        }
    }

    isValid () {
        //  Required fields if specific review < 3 and
        //  both no tag and no specific body
        //  (for instance ambiance < 3 and no ambiance_body
        //  and no tags for ambiance)

        /*
        return _(this.model.fields).every((field) => {
            return this.model.review[field + '_body'].length ||
                this.model.review[field] > 3 ||
                _(this.model.review.tags).some((tag) => {
                    return tag.type === field
                })
        })
        */
        return true
    }

    setFields () {
        this.fields = this.model.fields.sort((a, b) => {
            return this.model.review[a] - this.model.review[b]
        })
    }

    setTags () {
        const polarityTags = _(this.model.polarityTags)
            .groupBy('type')

        this.polarityTags = this.model.fields.reduce((obj, type) => {
            // Fill selected tags
            let polarity = _(polarityTags.get(type)).groupBy('polarity')
            let positiveTags = _.shuffle(polarity.get('positive'))
            let negativeTags = _.shuffle(polarity.get('negative'))

            let selectedTags = []
            _.times(6, () => {
                if (Math.random() < this.tagsRatios[this.model.review[type]]) {
                    selectedTags.push(positiveTags.pop())
                } else {
                    selectedTags.push(negativeTags.pop())
                }
            })

            obj[type] = selectedTags
            return obj
        }, {})
    }

    ratingClass (field) {
        let rating = this.model.review[field]
        if (rating < 3) {
            return 'note-bad'
        } else if (rating === 3) {
            return 'note-medium'
        }
        return 'note-good'
    }

    getPlaceholder (rating) {
        if (rating < 3) {
            return 'what_you_didnt_like'
        } else if (rating === 3) {
            return 'what_you_thought'
        }
        return 'what_you_liked'
    }

    toggleTag (tag) {
        let index = this.model.review.tags.indexOf(tag)
        if (index > -1) {
            this.removeTag(index)
        } else {
            this.addTag(tag)
        }
    }

    addTag (tag) {
        this.model.review.tags.push(tag)
    }

    removeTag (index) {
        this.model.review.tags.splice(index, 1)
    }

    isTagInModel (tag) {
        return _(this.model.review.tags).indexOf(tag) > -1
    }

    getStep () {
        return this.model.step === 3
    }
}

StepThreeController.$inject = ['$scope', 'reviewsModel']
