import stepThreeController from './stepThree.controller.js'
import stepThreeTemplate from './stepThree.html'

export default {
    controllerAs: 'vm',
    bindings: {
    },
    controller: stepThreeController,
    template: stepThreeTemplate
}
