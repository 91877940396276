export default class StepTwoController {
    constructor (reviewsModel) {
        this.model = reviewsModel
    }

    isValid () {
        return this.model.review.service > 0 &&
            this.model.review.menu > 0 &&
            this.model.review.ambiance > 0 &&
            this.model.review.value_for_money > 0
    }
}

StepTwoController.$inject = ['reviewsModel']
