export default class TextRatingController {
    constructor ($translate) {
        this.mentions = {
            0: 'Selectionnez',
            1: 'Nul',
            2: 'Mauvais',
            3: 'Moyen',
            4: 'Bon',
            5: 'Excellent'
        }
        $translate.onReady(() => {
            this.mentions = {
                0: $translate.instant('mentions_select'),
                1: $translate.instant('mentions_very_bad'),
                2: $translate.instant('mentions_bad'),
                3: $translate.instant('mentions_average'),
                4: $translate.instant('mentions_good'),
                5: $translate.instant('mentions_very_good')
            }
        })
    }

    mention () {
        if (this.hover !== undefined) {
            return this.mentions[this.hover]
        }
        return this.mentions[this.rating]
    }

    ratingClass () {
        if (this.hover !== undefined && this.hover !== this.rating) {
            return 'active'
        }
        if (this.rating === 0) {
            return ''
        } else if (this.rating < 3) {
            return 'note-bad'
        } else if (this.rating === 3) {
            return 'note-medium'
        }
        return 'note-good'
    }
}

TextRatingController.$inject = ['$translate']
