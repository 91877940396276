routes.$inject = ['$stateProvider']

export default function routes ($stateProvider) {
    $stateProvider
        .state('reviews', {
            url: '/:restaurantId/:bookingId?{guest_id:int}',
            template: require('./reviews.html'),
            controller: 'ReviewsController',
            controllerAs: 'vm',
            resolve: {
                getModelData: ['reviewsModel', '$stateParams', (reviewsModel, $stateParams) => {
                    return reviewsModel.init($stateParams.restaurantId, $stateParams.bookingId, $stateParams.guest_id)
                }]
            }
        })
        .state('oldReviews', {
            url: '/?bid',
            resolve: {
                goToOldReviews: ['$window', '$stateParams', ($window, $stateParams) => {
                    if ($stateParams.bid !== undefined) {
                        $window.location.href = `http://reviews.zenchef.dev/?bid=${$stateParams.bid}`
                    }
                }]
            }
        })
}
