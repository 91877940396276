export default class StepOneController {
    constructor (reviewsModel) {
        this.model = reviewsModel
    }

    isValid () {
        return this.model.review.global > 0
    }

    nextStep () {
        if (this.isValid()) {
            this.model.nextStep()
        }
    }

    isValidWithComment () {
        return this.isValid() && this.model.review.body !== ''
    }
}

StepOneController.$inject = ['reviewsModel']
