import angular from 'angular'
import uirouter from 'angular-ui-router'
import translate from 'angular-translate'
import translateStatic from 'angular-translate-loader-static-files'

// import oclazyload from 'oclazyload'

import routing from './app.config'
import constants from './app.constants'
import reviews from './features/reviews'

function MainController () {}

var myApp = angular
    .module('reviewsForm', [uirouter, reviews, constants, translate, translateStatic])
    .config(routing)

myApp.controller('MainController', MainController)

myApp.filter('htmlSafe', ['$sce', function ($sce) {
    return function (htmlCode) {
        return $sce.trustAsHtml(htmlCode)
    }
}])
