import moment from 'moment'

export default function routing ($stateProvider, $urlRouterProvider, $locationProvider, $translateProvider) {
    $locationProvider.html5Mode(true)
    $translateProvider.useStaticFilesLoader({
        prefix: '/locales/',
        suffix: '.json'
    })
    let lang = ''

    let preferredLanguage = (window.navigator.languages && window.navigator.languages.length)
        ? window.navigator.languages[0]
        : (window.navigator.language || window.navigator.userLanguage)

    switch (preferredLanguage.toUpperCase()) {
    case 'EN-US':
    case 'EN':
    case 'EN-UK':
        lang = 'en'
        break
    case 'ES-ES':
    case 'ES':
        lang = 'es'
        break
    case 'FR':
    case 'FR-FR':
    case 'FR-BE':
    case 'FR-CH':
        lang = 'fr'
        break
    case 'NL':
    case 'NL-NL':
    case 'NL-BE':
        lang = 'nl'
        break
    case 'IT':
    case 'IT-IT':
    case 'IT-CH':
        lang = 'it'
        break
    case 'DE':
    case 'DE-CH':
    case 'DE-DE':
        lang = 'de'
        break
    case 'CS':
    case 'CS-CS':
        lang = 'cs'
        break
    default:
        lang = 'en'
        break
    }

    if (window.location.search.split('lang=')[1] !== undefined) {
        lang = window.location.search.split('lang=')[1].split('&')[0]
    }

    moment.locale(lang)
    $translateProvider.fallbackLanguage(lang)
    .preferredLanguage(lang)
    .useSanitizeValueStrategy('sceParameters', 'sanitizeParameters')
}

routing.$inject = ['$stateProvider', '$urlRouterProvider', '$locationProvider', '$translateProvider']
