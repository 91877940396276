import angular from 'angular'
import uirouter from 'angular-ui-router'

import routing from './reviews.routes'
import reviewsModel from './reviews.model'
import reviewsController from './reviews.controller'
import starsComponent from '../../components/stars'
import textRating from '../../components/textRating'

import stepOne from './components/stepOne'
import stepTwo from './components/stepTwo'
import stepThree from './components/stepThree'
import stepFour from './components/stepFour'
import bugsnag from 'bugsnag-js'

angular.module('exceptionOverride', [])

// Only initialize bugsnag in production and when the API key is set
if (process.env.BUGSNAG_API_KEY !== undefined && process.env.BUGSNAG_API_KEY !== '') {
    const bugsnagClient = bugsnag({
        apiKey: process.env.BUGSNAG_API_KEY || '',
        metaData: {
            restaurant_id: window.location.pathname.split('/')[1],
            booking_id: window.location.pathname.split('/')[2]
        }
    })

    angular
        .module('exceptionOverride')
        .factory('$exceptionHandler', function () {
            return function (exception, cause) {
                bugsnagClient.notify(exception, {
                    beforeSend: function (report) {
                        report.updateMetaData('angular', { cause: cause })
                    }
                })
            }
        })
}

export default angular
    .module('app.reviews', [uirouter, 'exceptionOverride'])
    .config(routing)
    .controller('ReviewsController', reviewsController)
    .service('reviewsModel', reviewsModel)
    .component('stars', starsComponent)
    .component('stepOne', stepOne)
    .component('stepTwo', stepTwo)
    .component('stepThree', stepThree)
    .component('stepFour', stepFour)
    .component('textRating', textRating).name
