import textRatingController from './textRating.controller.js'
import textRatingTemplate from './textRating.html'

export default {
    controllerAs: 'vm',
    bindings: {
        rating: '<',
        hover: '<'
    },
    controller: textRatingController,
    template: textRatingTemplate
}
