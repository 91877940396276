import _ from 'lodash'

export default class ReviewsController {
    constructor ($http, reviewsModel) {
        this.$http = $http
        this.model = reviewsModel
        this.url = `${this.model.API_URL}/restaurants/${this.model.RESTAURANT_ID}/reviews`
        this.model.loadTags()
        this.postingBooking = false
    }

    submitReviewsForm (event) {
        if (!this.postingBooking) {
            this.postingBooking = true
            this.$http
                .post(this.url, this.transformReview(this.model.review))
                .then(response => {
                    this.model.stats_same_rating =
                        response.data.stats_same_rating
                    window.localStorage.setItem('token', response.data.token)
                    this.model.nextStep()
                })
                .catch(() => { this.postingBooking = false })
            return false
        }
    }

    transformReview (review) {
        return _.mapValues(review, (val, key) => {
            if (key === 'tags') {
                return _(val).map('id')
            }
            return val
        })
    }
}

ReviewsController.$inject = ['$http', 'reviewsModel']
