import angular from 'angular'

let app = angular.module('app.constants', [])

app.constant('APP_URL', process.env.APP_URL || 'https://app.zenchef.com')
app.constant('API_URL', process.env.API_URL || '//api.zenchef.com/api/v1')
app.constant('CGU_URL', process.env.CGU_URL || 'https://bookings.zenchef.com/gtc')
app.constant('CONTACT_MAIL', process.env.CONTACT_MAIL || 'help@zenchef.com')

export default app.name
