import stepFourController from './stepFour.controller.js'
import stepFourTemplate from './stepFour.html'

export default {
    controllerAs: 'vm',
    bindings: {
    },
    controller: stepFourController,
    template: stepFourTemplate
}
