export default class StarsController {
    lessThan (number) {
        return this.rating < number
    }

    setHover (number) {
        this.hover = number
    }

    resetHover () {
        this.hover = undefined
    }
}
