import starsController from './stars.controller.js'
import starsTemplate from './stars.html'

export default {
    controllerAs: 'vm',
    bindings: {
        rating: '=?',
        hover: '=?'
    },
    controller: starsController,
    template: starsTemplate
}
