import stepOneController from './stepOne.controller.js'
import stepOneTemplate from './stepOne.html'

export default {
    controllerAs: 'vm',
    bindings: {
    },
    controller: stepOneController,
    template: stepOneTemplate
}
