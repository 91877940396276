import stepTwoController from './stepTwo.controller.js'
import stepTwoTemplate from './stepTwo.html'

export default {
    controllerAs: 'vm',
    bindings: {
    },
    controller: stepTwoController,
    template: stepTwoTemplate
}
