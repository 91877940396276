export default class StepFourController {

    constructor ($http, reviewsModel) {
        const vm = this
        this.$http = $http
        this.model = reviewsModel
        this.guestFlag = false
        this.guestFormSent = false
        this.fulfillFlag = false
        this.fulfillFormSent = false
        this.validForm = true

        this.state = {
            firstname: undefined,
            lastname: undefined,
            email: undefined,
            birthdate: undefined,
            phone: undefined,
            unsubscribed: false,
            token: window.localStorage.getItem('token'),

            get language () {
                return window.navigator.language
            },

            get version () {
                return vm.model.BOOKING_ID % 2 === 0 ? 'a' : 'b'
            },

            get isIdfRestaurant () {
                if (reviewsModel.booking.restaurant.country_code === 'fr') {
                    if (['75', '77', '78', '91', '92', '93', '95'].includes(reviewsModel.booking.restaurant.zip.substring(0, 2))) {
                        return true
                    } else {
                        return false
                    }
                } else {
                    return false
                }
            }
        }
        let fieldsHasValue = (this.model.booking && this.model.booking.customersheet && this.model.booking.customersheet.fields_has_value) ? this.model.booking.customersheet.fields_has_value : {}
        let modifiable = Object.keys(fieldsHasValue).every(key => fieldsHasValue[key])
        this.fulfillFormExists = !modifiable
    }

    submitGuestForm () {
        if (this.guestFlag) {
            return
        }

        let guests = this.model.guests.filter((guest) => guest.email !== undefined)
        const url = `${this.model.API_URL}/restaurants/${this.model.RESTAURANT_ID}/bookings/${this.model.BOOKING_ID}/guests`

        this.guestFlag = true
        this.$http.post(url, guests)
            .then((response) => {
                this.guestFlag = false
                this.guestFormSent = true
                setTimeout(() => {
                    document.getElementById('checkGuests').className += ' hidden'
                }, 1000)
            }, (err) => {
                console.error(err)
                this.guestFlag = false
            })
    }

    submitFulfillProfile () {
        if (this.fulfillFlag) {
            return
        }

        const url = `${this.model.API_URL}/restaurants/${this.model.RESTAURANT_ID}/bookings/${this.model.BOOKING_ID}/customer-sheets/` + this.state.token

        let fulfill = JSON.parse(JSON.stringify(this.state))
        fulfill.unsubscribed = !(fulfill.unsubscribed)

        this.fulfillFlag = true
        if (fulfill.birthdate) fulfill.birthdate = new Date(fulfill.birthdate).toISOString().slice(0, 10)

        this.$http.put(url, fulfill)
            .then((response) => {
                this.fulfillFlag = false
                this.fulfillFormSent = true
                setTimeout(() => {
                    document.getElementById('checkFulfill').className += ' hidden'
                }, 1000)
            }, (err) => {
                console.error(err)
                this.fulfillFlag = false
            })
    }
}

StepFourController.$inject = ['$http', 'reviewsModel']
